@use "../core/main";

.footer-container {
  background-color: main.$secondary-color;
  
  box-sizing: 0;
}

.footer-links {
  margin-top: -10px;
  color: white !important;
  font-weight: 100 !important;
  font-size: 1rem !important;
}

.mobile-footer-links {
   
    margin-top: -10px;
  color: white !important;
  font-weight: 100 !important;
  font-size: 1rem !important;
}

.footer-line {
  width: 80%;
  height: 2px;
  background-color: main.$primary-color;
}

.footerNavLink-arrow {
    border: 3px solid main.$info-color;
    border-bottom: 8px;
    border-right: 8px;
    width: 18px;
    height: 18px;
    transform: translate(1px, 0px) rotate(225deg);
    transition: all .5s ;

    &.rotate{
        transform: translate(0px, 8px)rotate(45deg);
    }
}

.footerNavLink-slider {
    display:none;
   

    &.close{
       display: block;
    }
}

