@use '../core/main';

.nav-info-container {
    color: main.$text-light;
    // width: 400px;
    height: 2rem;
}

.nav-search-icon {
    width: 20px;
}


.nav-search-container {
    margin-left: 20px;
    margin-right: 20px;
}

.nav-call-text {
    margin-top: 4.5px;
}

.nav-element {
    transition: all;
}
