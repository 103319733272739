@use "../core/main";
.tile-line {
    background-color: main.$info-dark;
    width: 60px;
    height: 3px;
    transition: all .25s ease;
}

.tile{
    text-decoration: none;
    transition: all .25s ease;
}

.tile:hover{
    transform: scale(1.01);
    .tile-line {
        background-color: main.$info-dark;
        width: 100px;
        height: 3px;
    }
    
}