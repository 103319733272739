@use "../core/main";

.form {
    &-message{
        transform: translateY(1rem);
        transition: all .5s ease;
        opacity: 0;
        display: none;
        z-index: 0;
    }
}
.form-message{

}

.form-message-show{
    opacity:1;
    transform: translateY(-.5rem);
}
.form-message-block{
    display: block;
}

.form-success {
    background-color:forestgreen;
}

.form-failure {
    background-color:darkred ;
}

.input:required::placeholder:after {
    content: " *";
    color: red;
}



.form.form-control:focus {
    border-color: main.$focus-color;
    box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.075), 0 0 8px main.$focus-color;
}

.search.form-control:focus {
    border-color: #ffffff;
    box-shadow: 0 0 8px #ffffff;
}
.contact-form{
    &__control{
        position: relative;
        font-family: "Monstserrat", sans-serif;
        font-weight: 200;
        color: #212529;

       .input-field{
            width: 100%;
            border-radius: 0;
            padding: .85rem;


           &:focus  ~ .input-label, &:not(:placeholder-shown) ~ .input-label{

               z-index: -1 ;

           }

           &:required  ~ .input-label:after {
               content: " *";
               color: main.$primary-color;
           }

        }

        .input-label{
            position: absolute;
            left: 1rem;
            top: 28px;
            transform: translateY(-50%);
            pointer-events: none;


        }



    }
}