
.title-form {
    position: relative;
    transition: all .25s ease;

}
.heroText{
    font-size: 9vw;
    transform: translateY(-1rem);
}

@media screen and (min-width: 450px) {
    .title-form{
        
        
        transform: translateY(-50px);
    }
    .heroText{
        font-size: 6vw;
    }
    
}

@media screen and (min-width: 992px) {
    .title-form{
        
        
        transform: translateY(-50px);
    }
    .heroText{
        font-size: 5vw;
    }
}

@media screen and (min-width: 1200px) {
    .title-form{
        //position: absolute;
        transform: translateY(-50px);
    }

    .heroText{
        font-size: 3rem;
    }
}