.search-bubble {
  border-radius: 2em;
  
  background-color: #fff;
  padding: 0.75em 0.75em 0.75em 1.75em;
  border: none;

  form {
    

    input {
      border: none !important;
      outline: none !important;
      border-width: 0px;
    }
    input:focus{
      outline: none !important;
    }

    button {
      background-color: white;
      border: none;

      img{
        background-color: white ;
        border: none;
      }
    }
  }
}
