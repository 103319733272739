@use "../core/main";

.nav-main {
  height: 7rem;
  transition: all 0.35s ease-in-out;
}

.nav-inner-container {
  // max-width: 1250px !important;
}

.nav-main.solid {
  background: main.$secondary-color;
  height: 5.5rem;
}

.nav-gradient {
  position: absolute;
  //margin-left: 12px;
  width: 100%;
  background: main.$secondary-color;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.49625787815126055) 100%);
  background-blend-mode: multiply;
  pointer-events: none !important;
  // width: 100vw;
  height: 20rem;
  z-index: -1;
  transition: all 1s;

  &.fade {
    transform: translateY(3rem);
  }
}

.nav-container {
  //   transform: translateY(-6.75rem);
  //margin-left: .4rem;
  transform: translateY(-1rem);
  transition: all 0.35s ease-in-out;
  z-index: 60 !important;
}
.nav-container.move {
  //   transform: translateY((-7.75rem));
  transform: translateY((-1.75rem));
}

.nav-search {
  transform: translateY(0);
    opacity: 1;
    animation: .5s ease-out 0s 1 slideSearchIn forwards;
    z-index:-1 !important;
  &.nav-hide {
    animation: .5s ease-out 0s 1 slideSearchOut forwards;
    z-index:-1 !important;
  }
}

@media screen and (min-width: 468px) {
  .nav-main.solid {
    background: main.$secondary-color;
    height: 7rem;
  }

  .nav-container {
    //   transform: translateY(-6.75rem);
    margin-left: .7rem;
    transform: translateY(-.5rem);
    transition: all 0.35s ease-in-out;
    z-index: 60 !important;
  }
  .nav-container.move {
    //   transform: translateY((-7.75rem));
    transform: translateY((-1rem));
  }
}

@media screen and (min-width: 800px) {
  .nav-main.solid {
    background: main.$secondary-color;
    height: 10rem;
  }

  .nav-container {
    //   transform: translateY(-6.75rem);
    margin-left: 1.3rem;
    transform: translateY(1rem);
    transition: all 0.35s ease-in-out;
    z-index: 60 !important;
  }
  .nav-container.move {
    //   transform: translateY((-7.75rem));
    transform: translateY((0.5rem));
  }
}
.mavbar-main-logo{
  z-index: 9999;
}

@keyframes slideSearchIn {
  0%{
    transform: translateY(-6rem);
    opacity: 0;
    display: none;
  }
  50%{
    transform: translateY(-6rem);
    opacity: 0;
    display: block;
  }
  100%{
    transform: translateY(0rem);
    opacity: 1;
    display: block;
  }
}

@keyframes slideSearchOut {
  0%{
    transform: translateY(0);
    opacity: 1;
    display: block;
  }
  1%{
    transform: translateY(0rem);
    opacity: 1;
    display: block;
  }
  50%{
    transform: translateY(-6rem);
    opacity: 0;
    display: block;
  }
  100% {
    transform: translateY(-6rem);
    opacity: 0;
    display: none;
  }
}
