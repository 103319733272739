.nav-top-text{
    color: white !important;
    font-weight:500 !important;
    font-size: 1.75rem !important;
}



.nav-bottom-text{
    color: white !important;
    font-weight:300 !important;
    font-size: 1rem !important;
}