@use '../core/main';
.main-logo {
    z-index: 10;
    position: relative;
}

.menu-active{
    color: main.$primary-color;
    opacity: 1;
}

.menu-container {
   
    position: fixed;
    opacity: 1;
    top: 0;
    z-index: -1 !important;
}

// .menu-left-column {
//     // flex: 0 0 990px;
// }

.menu-right-container-background {
    
    width: 100%;
    background-image: url("./../../../public/img/tyler.webp");
    background-position: center;
    background-size: cover;
    background-position-x: left;
    opacity: .2;
}


.menu-right-innner-container {
    position: absolute;
    margin-top: 250px;
    color:white;


   .container{
    width: 476px;
    
    
        img{
            // margin-left:-15px;
            margin-bottom: 30px;
        }
        .quote{
            
            font-family:'Times New Roman', Times, serif;
            font-weight: light;
            font-size: 1.4rem;
            line-height: 1.75rem;
           
           
        }
        .author{
            
            margin-top: 3rem;
            font-weight: 100;
            font-size: .9rem;
            line-height: 1.75rem;
        }
   }
    
}



.nav-link-spacer {
    margin-top: 225px;
   
}

.menu-hidden{
    display: none;
}


@keyframes fadeOut {
    0%{
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    95%{
        opacity: 1;
    }
    100% {
        opacity: 1;
        display: block;
    }
}
// .main-logo {
//     width: 200px;
//     transition: all .5s ease;
   
// }

// @media screen and (min-width:600px){
//     .main-logo {

//         width: 275px;
//     }
// }

// @media screen and (min-width: 950px) {
//     .main-logo {

//         width: 350px;
//     }
// }